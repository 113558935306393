<template>
    <div class="wrapper blog-posts">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('/img/bg39.jpg') ;">
            </parallax>
            <!--<div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Blog Posts</h2>
                        <a href="#button" class="btn btn-primary btn-round  btn-icon">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#button" class="btn btn-primary btn-round  btn-icon">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>-->
        </div>
        <div class="main">
            <div class="container">
                <div class="section">
                	<h3 class="title text-center">Latest Blog Posts</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card card-plain card-blog">
                                <div class="card-image">
                                    <a href="/uni/exploit-spacing-effect-to-learn-more-effectively/">
                                        <img class="img rounded img-raised" src="/img/blog/spaced-rep/book-library-with-open-textbook-350.jpg"/>
                                    </a>
                                </div>
                                <div class="card-body">
                                    <h6 class="category text-danger">
                                        <i class="now-ui-icons media-2_sound-wave"></i> Studying
                                    </h6>
                                    <h4 class="card-title">
                                        <a href="/uni/exploit-spacing-effect-to-learn-more-effectively/">Exploit the spacing effect to learn more effectively</a>
                                    </h4>
                                    <p class="card-description">
                                        It is pretty easy to get into lousy learning and revision habits. Putting hours into revising for an exam only to be disappointed with the results should be a matter of the past.
                                        <br>
                                        <a href="/uni/exploit-spacing-effect-to-learn-more-effectively/"> Read More </a>
                                    <div class="author">
                                        <img v-lazy="'/img/placeholder.jpg'" alt="..." class="avatar img-raised">
                                        <span>Bahtic</span>
                                    </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <card type="blog" plain>
                                <img slot="image" class="img rounded img-raised" src="/img/bg27.jpg"/>
                                <h6 class="category text-info">Note Taking</h6>
                                <h4 class="card-title">
                                    <a href="/uni/find-own-study-method-take-better-notes-at-university/">How To Take Better Notes at University</a>
                                </h4>
                                <p class="card-description">
                                    Being a student is all about personal growth and developing the skills you will need later in life. To make the most of it, you have to pay attention to every little detail regarding your student life, including your notes.
                                    <a href="/uni/find-own-study-method-take-better-notes-at-university/"> Read More </a>
                                </p>
                                <div class="author">
                                    <img v-lazy="'/img/marie.jpg'" alt="..." class="avatar img-raised">
                                    <span>A. Valoschi</span> <!--Adelina Valoschi -->
                                </div>
                            </card>
                        </div>
                        <div class="col-md-4">
                            <card type="blog" plain>
                                <img slot="image" class="img rounded img-raised" src="/img/fp/young-writer-taking-notes.jpg"/>
                                <h6 class="category text-success">Studying</h6>
                                <h4 class="card-title">
                                    <a href="/uni/importance-of-free-time-for-concentration/">The importance of free time for concentration</a>
                                </h4>
                                <p class="card-description">
                                    Continuing on the topic of concentration, we can now explore how the way you manage your time in and out of work sessions influences your concentration quality and span.
                                    <br><a href="/uni/importance-of-free-time-for-concentration/"> Read More </a>
                                </p>
                                <div class="author">
                                    <img v-lazy="'/img/placeholder.jpg'" alt="..." class="avatar img-raised">
                                    <span>A. Santacoloma</span>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section pt-0 pb-0">
                <div class="team-3 section-image" style="background-image: url('/img/bg26.jpg')">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 ml-auto mr-auto text-center">
                                <h2 class="title">Learn Mode</h2>
                                <h4 class="description">
                                    COMING SOON...
                                </h4>
                            </div>
                            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
                                <card type="profile">
                                    <div slot="raw-content" class="row">
                                        <div class="col-md-5">
                                            <div class="card-image">
                                                <a href="#">
                                                    <img class="img" src="img/placeholder.jpg">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="card-body">
                                                <h3 class="card-title">Flashcards</h3>
                                                <h6 class="category text-primary"> University</h6>
                                                <p class="card-description">
                                                    ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                            </div>
                            <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
                                <card type="profile">
                                    <div slot="raw-content" class="row">
                                        <div class="col-md-5">
                                            <div class="card-image">
                                                <a href="#">
                                                    <img class="img" src="/img/placeholder.jpg">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="card-body">
                                                <h3 class="card-title">Productivity</h3>
                                                <h6 class="category text-primary">School</h6>
                                                <p class="card-description">
                                                    ...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subscribe-line subscribe-line-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="title">Get Tips &amp; Tricks every Month!</h4>
                            <p class="description">
                                Join our newsletter and get news in your inbox! We hate spam too, so no
                                worries about this.
                            </p>
                        </div>
                        <div class="col-md-6">
                            <card plain class="card-form-horizontal">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <fg-input addon-left-icon="now-ui-icons ui-1_email-85"
                                                  placeholder="Email Name...">
                                        </fg-input>
                                    </div>
                                    <div class="col-sm-4">
                                        <n-button type="primary" round block>Subscribe
                                        </n-button>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import { Card, Button, FormGroupInput } from '@/components';

  export default {
    name: 'blog-posts',
    bodyClass: 'blog-posts',
    components: {
      Card,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        year: new Date().getFullYear()
      }
    },
  }
</script>
<style>

</style>
