<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('/img/blog/spaced-rep/book-library-with-open-textbook-1800.jpg')"></parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Spaced Repetition</h2>
                        <h4>Exploit the psychological spacing effect to learn more effectively</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <!--<div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <a href="#" class="btn btn-primary btn-round btn-lg">
                                <i class="now-ui-icons text_align-left"></i> Read Article
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-twitter btn-round">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-facebook btn-round">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-google btn-round">
                                <i class="fab fa-google"></i>
                            </a>
                        </div>
                    </div>
                </div>-->
            </div>
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto" style="margin-top: -50px;">
                            <h2>Exploit the psychological spacing effect to learn more effectively</h2>
                            <p>Can you remember those days when you used to pull all-nighters, cramming the whole book before the test? You probably forgot what you have learned in such a short time. On the other hand, materials you have learned over time, such as multiplication tables you can easily remember in a second.
                            </p>

                            <h3>Store more information with the the Spaced Repetition technique</h3>

                            <p>
                                It is pretty easy to get into lousy learning and revision habits. Putting hours into revising for an exam only to be disappointed with the results should be a matter of the past. Lawyers are using it to pass the Bar, and it is a massive hit among medical students trying to learn all those complicated terms and definitions. The technique is called Spaced Repetition, and you should memorize it because it will change the way you perceive your memory. Furthermore, you will start to like the studying process!
                              </p>

                            <p>
                                Transferring information into long-term memory is far more complicated than short-term memories. In the 19th century, a German researcher, Hermann Ebbinghaus, measured how long it takes for an average man to learn a list of random words showing that over 90% of the information usually disappears within days. Based on his analysis, the best time to remember something is when we start to forget it. It strengthens our neural pathways or synapses, which carry every thought and sensation, providing information through the nervous system. Spaced Repetition is a memory phenomenon based on a spacing effect, which means that new materials need to be revised at periodic intervals. The intervals are first spaced closely together to ingrain new information in our memory (in a few hours or within a day). As the materials are remembered, the time intervals become longer (weeks or months). This system of acquiring new information allows us to review the material right before it is forgotten, helping us memorize it for a longer time. By spacing out our learning over time, we are doing the opposite of „cramming“. We are learning for life.

                            </p>

                            <h3>The Forgetting Curve - Why We Forget, and What We Can Do</h3>

                            <p>
                                Many studies in educational psychology have demonstrated that the cramming technique proves to be effective only when it comes to short-term memories. It has been found that learners forget almost
                                50–80% of all new information within a few hours or days. The concept of the Learning Curve is all about acquiring new information and the process of learning within the available time. The key element is timing for the learning process to be the most effective and productive, with real-life results. When we are planning the perfect timing for studying, we need to remember the process of forgetting, i.e., the Forgetting Curve. Ebbinghaus was the Erst psychologist to analyze the memories by creating a chart to show the rate at which memories decay. He called it the Forgetting Curve i.e. the time after studying when the acquired information is on the verge of forgetting. By Ending out when our Forgetting Curve starts to decay, we allow our learning process to build and unlock our super memory.
                            </p>

                            <h3>How efficient is the Spaced Repetition learning method</h3>

                            <p>
                                Even though acquiring and retaining knowledge truly matters in education, a more crucial objective is our own ability to utilize what was learned and solve problems in real-life situations. As per statistics encountered in numerous experiments, the Spaced Repetition technique showed to be two or three times better than the knowledge acquired through the traditional process of studying. The participants of numerous studies have shown that by using this technique, long-term retention of the newly learned materials was improved by 200%. Spacing Repetition effects were especially noteworthy in one early study when college students were asked to learn the Athenian Oath. They were divided into two groups; the Erst group heard the oath six times in a row, while the other heard it three times the Erst day and three more times three days later. The quick test, which involved repeating the material in the first few hours, showed more productivity for the first group. However, four weeks later, when the participants were asked to repeat the material on the delayed test, the group using the Spaced Repetition technique outperformed the Erst group of participants. Even though the Erst group appeared to be more successful in the short-term task, the spacing effect played a crucial role in storing materials in our long-term memories. The research data were later confirmed within various studies, including the 2013 Human Neuroscience study showing a significantly higher efficiency of Spaced Repetition learning when applying to university exams. All in all, the Spaced Repetition technique holds great promise as a study tool.
                            </p>

                            <h3 class="title">How to use Spaced Repetition in your learning</h3>
                            <p>
                                Spaced Repetition works and can be applied to any kind of learning because the technique tends to be complementary with other learning strategies. Some researchers suggest that it works best when learning information in chunks, i.e., pieces of information like definitions, formulas, new vocabulary, etc. If you are taking, for example, a new history or biology course, or you just want to learn a new language, the solution is the Spaced Repetition. It may take some time to create your own learning system, but it’s worth your while. For starters, these are some of the guidelines:
                            </p>
                            <ul>
                                <li>Break the materials into bite-sized pieces, especially if you have a large amount of data to be learned.</li>
                                <li>Review material while doing some everyday activities, and set up the best timing to review the materials.</li>
                                <li>Start at the beginning of the semester and make it an every- day habit.</li>
                                <li>Put the information in a context that can be easily remembered. Flashcards, whether paper or electronic, can be handy for this process.</li>
                                <li>During the gap period, engage yourself with some distracting activities. In that way, you are allowing your brain to store the newly acquired information. </li>
                            </ul>

                            <h3>The Box Method</h3>
                            <p>
                                Creating flashcards out of papers can be very useful for reviewing the learned materials. For example, you can create five boxes with flashcards, each being reviewed at a different time. The material inside the Erst box is reviewed each day, the second box once every three days, the third one every week, etc. Each time you create a new card, place it in the Erst box. Once the material has been correctly reviewed, place the card in the second box, and so on. However, the catch is that if a card in any of the boxes is forgotten and you cannot remember the answer, move it back to the Erst box. This is an excellent way of learning what you need to remember, and it will give you enough time to learn the information, which needs several review sessions.
                            </p>

                            <h3>Spaced Repetition Software</h3>
                            <p>
                                The Spaced Repetition software is a set of computerized flashcards, offering several other options such as grading yourself on the recall of data, setting timely alarms, etc. It enhances the learner’s interest and keeps him engaged. You can start by setting up an account on AutoLearnify. Create decks incorporating definitions, reversed definitions, questions that you can easily associate with the materials. Think of possible test questions and create both the questions and answers, adding a photo to them. This all is actually up to you. Bear in mind in what context you will be using this type of data. The software will also help you to discover your Forgetting Curve through the process of grading and by learning when exactly “do you forget.” For the cards you give yourself a bad grade, the software will represent it again within the next hours or the next day. Keep up with the new definitions and concepts as the semester progresses, and you will end up with a great set of study notes for the whole course.
                            </p>

                            <card type="profile" plain>
                                <template slot="raw-content">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="card-avatar">
                                                <a href="#">
                                                    <img class="img img-raised" src="/img/placeholder.jpg">
                                                </a>
                                                <div class="ripple-container"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <h4 class="card-title">By Bahtic</h4>
                                            <p class="description">.</p>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-default pull-right btn-round">Follow
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-blog-info">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="blog-tags">
                                        Tags:
                                        <span class="label label-primary">Spaced Repetition</span>
                                        <span class="label label-primary">Studying </span>
                                        <span class="label label-primary">Flashcards </span>
                                    </div>
                                </div>
                                <!--<div class="col-md-6">
                                    <a href="#todo" class="btn btn-google btn-round pull-right">
                                        <i class="fab fa-google"></i> X
                                    </a>
                                    <a href="#todo" class="btn btn-twitter btn-round pull-right">
                                        <i class="fab fa-twitter"></i> X
                                    </a>
                                    <a href="#todo" class="btn btn-facebook btn-round pull-right">
                                        <i class="fab fa-facebook-square"></i> X
                                    </a>
                                </div>-->
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
  import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

  export default {
    name: 'blog-post',
    bodyClass: 'blog-post',
    components: {
      Card,
      InfoSection,
      Badge,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
  }
</script>
<style>
</style>
