<template>
    <div class="cd-section" id="features" >
        <!--     *********     FEATURES 7      *********      -->
        <div class="features-7 section-image" style="background-image: url('/img/bg8.jpg')">
            <div class="col-md-8 mr-auto ml-auto text-center">
                <h2 class="title">Start learning more effective with AutoLearnify</h2>
                <h4 class="description">Study Keeps making learning more effective through Automatic Practice Testing.</h4>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 px-0">
                        <div class="col-sm-12">
                            <div class="info info-horizontal">
                                <div class="icon">
                                    <i class="now-ui-icons design-2_html5"></i>
                                </div>
                                <div class="description">
                                    <h5 class="info-title">Test Generation</h5>
                                    <p class="description">AutoLearnify automatically generates your flashcards based on the text you have to learn. </p>
                                </div>
                            </div>
                            <div class="info info-horizontal">
                                <div class="icon">
                                    <i class="now-ui-icons design_palette"></i>
                                </div>
                                <div class="description">
                                    <h5 class="info-title">Spaced Repetition</h5>
                                    <p class="description">The use of Spaced Repetition allows you to exploit the psychological spacing effect. This learning technique helps you to remember a large number of items indefinitely in memory.</p>
                                </div>
                            </div>
                            <div class="info info-horizontal">
                                <div class="icon">
                                    <i class="now-ui-icons design-2_ruler-pencil"></i>
                                </div>
                                <div class="description">
                                    <h5 class="info-title">Question Generation</h5>
                                    <p class="description">Machine-aided question formation helps you to intuitively learn any subject. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="image-container">
                            <img v-lazy="'/img/ipad3.png'" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END FEATURES 7      *********      -->
    </div>
</template>
<script>
  import { Card } from '@/components';
  export default {
    components: {
      Card
    }
  }
</script>
<style>
</style>
