<template>
    <div id="app">
        <router-view name="header"/>
        <div>
            <router-view/>
        </div>
        <router-view name="footer"/>
    </div>
</template>
<script>
export default {
  mounted() {
    window.ga =
      window.ga ||
      function() {
        (ga.q = ga.q || []).push(arguments);
      };
    ga.l = +new Date();
    ga("create", "UA-122951217-2", "auto");
    ga("send", "pageview");
    let anaScript = document.createElement("script");
    anaScript.setAttribute(
      "src",
      "https://www.google-analytics.com/analytics.js"
    );
    document.head.appendChild(anaScript);
  }
};
</script>
