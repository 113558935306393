<template>
    <footer class="footer"
            :class="{[`footer-${type}`]: type}"
            :data-background-color="backgroundColor">
                <div class="container">
                    <a class="footer-brand" href="/">&copy; {{year}}, AutoLearnify. All rights reserved.</a>
                    <ul class="pull-center">
                        <li>
                            <a href="/about" class="nav-link">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a href="/uni" class="nav-link">
                                Blog
                            </a>
                        </li>
                        <li>
                            <a href="/signup" class="nav-link">
                                Sign up
                            </a>
                        </li>
                    </ul>
                    <ul class="social-buttons pull-right">
                       <!-- <li>
                            <a href="https://twitter.com/autolearnify" class="btn btn-icon btn-neutral btn-twitter btn-round">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                        </li>-->
                        <!--<li>
                            <a href="https://www.facebook.com/AutoLearnify" target="_blank"
                               class="btn btn-icon btn-neutral btn-dribbble">
                                <i class="fab fa-dribbble"></i>
                            </a>
                        </li>-->
                        <!--<li>
                            <a href="https://www.instagram.com/learn.different/" target="_blank"
                               class="btn btn-icon btn-neutral btn-instagram">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>-->
                    </ul>
                </div>
            </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
</style>
