<template>
    <div class="cd-section" id="headers" >
        <!--     *********     HEADER 1      *********      -->
        <div class="header-1">
            <nav class="navbar navbar-expand-lg navbar-transparent bg-primary navbar-absolute">
                <div class="container">
                    <div class="navbar-translate">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#example-navbar-primary" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                        <a class="navbar-brand" href="#">AutoLearnify</a>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-primary2">
                        <ul class="navbar-nav mx-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#">
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    About Us
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                        <ul class="nav navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="https://twitter.com/">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://www.facebook.com/">
                                    <i class="fab fa-facebook-square"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://www.instagram.com/">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="page-header header-filter">
                <div class="page-header-image" style="background-image: url('/img/bg16.jpg')"></div>
                <div class="content-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-7 ml-auto text-right">
                                <h1 class="title">History of surfing</h1>
                                <h4 class="description">The riding of waves has likely existed since humans began
                                    swimming in the ocean. In this sense, bodysurfing is the oldest type of
                                    wave-catching. Standing up on what is now called a surfboard is a relatively recent
                                    innovation developed by the Polynesians.</h4>
                                <br/>
                                <div class="buttons">
                                    <a href="#" class="btn btn-icon btn-link btn-neutral btn-lg">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a href="#" class="btn btn-icon btn-link btn-neutral btn-lg">
                                        <i class="fab fa-facebook-square"></i>
                                    </a>
                                    <a href="#" class="btn btn-icon btn-link btn-neutral btn-lg">
                                        <i class="fab fa-get-pocket"></i>
                                    </a>
                                    <a href="#" class="btn btn-info btn-lg mr-3">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END HEADER 1      *********      -->
        <!--     *********     HEADER 2      *********      -->
        <div class="header-2">
            <nav class="navbar navbar-expand-lg navbar-transparent bg-primary navbar-absolute">
                <div class="container">
                    <div class="navbar-translate">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#example-navbar-primary" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                        <a class="navbar-brand" href="#">AutoLearnify</a>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-primary">
                        <ul class="navbar-nav mx-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#">
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    About Us
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    Products
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                        <ul class="nav navbar-nav navbar-right">
                            <li class="nav-item">
                                <a class="nav-link" href="https://twitter.com/">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://www.facebook.com/">
                                    <i class="fab fa-facebook-square"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://www.instagram.com/">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="page-header header-filter">
                <div class="page-header-image" style="background-image: url('/img/bg14.jpg')"></div>
                <div class="content-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 ml-auto mr-auto text-center">
                                <h1 class="title"> You should be here!</h1>
                                <h5 class="description">5,000 capacity venue, holding some of the latest technology
                                    lighting with a 24 colour laser system Amnesia is one of the islands most legendary
                                    clubs.</h5>
                            </div>
                            <div class="col-md-10 ml-auto mr-auto">
                                <div class="card card-raised card-form-horizontal card-plain" data-background-color>
                                    <div class="card-body">
                                        <form method="" action="">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <fg-input value="" placeholder="Full Name"></fg-input>
                                                </div>
                                                <div class="col-md-3">
                                                    <fg-input value="" placeholder="Your Email"></fg-input>
                                                </div>
                                                <div class="col-md-3">
                                                    <fg-input value="" placeholder="Password"></fg-input>
                                                </div>
                                                <div class="col-md-3">
                                                    <n-button type="primary" round block>Join Us</n-button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********    END HEADER 2      *********      -->
        <!--     *********     HEADER 3      *********      -->
        <div class="header-3">
            <nav class="navbar navbar-expand-lg navbar-transparent bg-primary navbar-absolute">
                <div class="container">
                    <div class="navbar-translate">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#example-navbar-primary" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                        <a class="navbar-brand" href="#">AutoLearnify</a>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-primary">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#">
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    About Us
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <el-carousel height="100vh">
                <el-carousel-item>
                    <div class="page-header header-filter">
                        <div class="page-header-image"
                             style="background-image: url('/img/bg20.jpg')">
                        </div>
                        <div class="content-center">
                            <div class="container text-left">
                                <div class="content-center">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="iframe-container">
                                                <iframe height="250"
                                                        src="https://www.youtube.com/embed/rmfmdKOLzVI?rel=0&amp;controls=0&amp;showinfo=0"
                                                        frameborder="0" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ml-auto mr-auto text-right">
                                            <h1 class="title">On the run tour.</h1>
                                            <h4 class="description ">On the Run Tour: Beyoncé and Jay Z is a 2014
                                                concert special which documents the September 12 and 13, 2014, shows
                                                of American singers' Beyoncé and Jay-Z joint co-headlining venture
                                                On the Run Tour.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="page-header header-filter">
                        <div class="page-header-image"
                             style="background-image: url('/img/bg15.jpg')">
                        </div>
                        <div class="content-center">
                            <div class="container">
                                <div class="content-center">
                                    <div class="row">
                                        <div class="col-md-8 ml-auto mr-auto text-center">
                                            <h1 class="title">Island of legends.</h1>
                                            <h4 class="description ">The islands of Malta and Gozo are brilliant for
                                                a family holiday, packed with fun places to visit whatever your
                                                children’s ages. The islands’ small size means everywhere is within
                                                easy reach.</h4>
                                            <br/>
                                            <h5>Connect with us on:</h5>
                                            <div class="buttons">
                                                <a href="#"
                                                   class="btn btn-icon btn-neutral btn-danger btn-round mt-2">
                                                    <i class="fab fa-twitter"></i>
                                                </a>
                                                <a href="#"
                                                   class="btn btn-icon btn-neutral btn-danger btn-round mt-2">
                                                    <i class="fab fa-facebook-square"></i>
                                                </a>
                                                <a href="#"
                                                   class="btn btn-icon btn-neutral btn-danger btn-round mt-2">
                                                    <i class="fab fa-google-plus"></i>
                                                </a>
                                                <a href="#"
                                                   class="btn btn-icon btn-neutral btn-danger btn-round  mt-2">
                                                    <i class="fab fa-instagram"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="page-header header-filter">
                        <div class="page-header-image"
                             style="background-image: url('/img/bg17.jpg')">
                        </div>
                        <div class="content-center">
                            <div class="container">
                                <div class="content-center">
                                    <div class="row">
                                        <div class="col-md-6 text-left">
                                            <h1 class="title">Arctic Sea ice.</h1>
                                            <h4 class="description ">According to the National Oceanic and
                                                Atmospheric Administration, Ted Scambos, NSIDC lead scientist, puts
                                                the potentially record low maximum sea ice extent this year down to
                                                low ice extent in the Pacific and a late drop in ice extent in the
                                                Barents Sea.</h4>
                                            <br/>
                                            <div class="buttons">
                                                <a href="#" class="btn btn-neutral btn-primary btn-lg mr-1">
                                                    <i class="now-ui-icons files_single-copy-04"></i> Read More..
                                                </a>
                                                <a href="#" class="btn btn-primary btn-lg">
                                                    <i class="now-ui-icons arrows-1_share-66"></i> Subscribe
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!--     *********    END HEADER 3      *********      -->
    </div>
</template>
<script>
  import { FormGroupInput, Button } from '@/components';
  import { Carousel, CarouselItem } from 'element-ui'
  export default {
    components:{
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem
    }
  }
</script>
<style>
</style>
