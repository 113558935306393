<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('/img/bg24.jpg')"></parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">How to Take Better Notes at University</h2>
                        <h4>Improve your note taking skills.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <!--<div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <a href="#" class="btn btn-primary btn-round btn-lg">
                                <i class="now-ui-icons text_align-left"></i> Read Article
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-twitter btn-round">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-facebook btn-round">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-google btn-round">
                                <i class="fab fa-google"></i>
                            </a>
                        </div>
                    </div>
                </div>-->
            </div>
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto" style="margin-top: -50px;">
                            <p>Being a student is all about personal growth and developing the skills, you will need later in life. To make the most of it, you have to pay attention to every little detail regarding your student life, including your notes.</p>
                            <h3 class="title">TNOTE TAKING METHODS — find your own study method</h3>
                            <p>Since there is no standard note-taking technique for everyone, there are undoubtedly many ways in which you can take your notes. It might sound like a cliché, but the most important thing is finding your own note-taking method to help you study effectively for your exams. In order to do that, you should try them all and find the best one for yourself, preferably before the university starts, so you can be ready to start something that matches your style.
                                <br/>I will talk to you about some of the methods I have tried over the years.</p>
                            <div><h3 class="title">THE CORNELL METHOD</h3>
                                <img
                                        src="/img/blog/note-taking-aval/cornell-note-taking-method.png"
                                        alt="Illustration of THE CORNELL METHOD"
                                        height="350px"
                                        style="float: right;margin-top: 0px"
                                />
                            <p>
                                You need to divide the page in 3 different sections for this method, as shown in the picture.</p>
                            <p>You can write your class notes in the main section, use the left section for vocabulary or for any questions you might need to find the answer to, and the bottom section for a short summary of the notes.</p>
                            <p>This is a well-organized and neat note-taking method that should help you learn the material quickly and easily, finding all the information you need a lot faster.
                            </p>

                        </div>
                            <div style="margin-top: 30px;">
                                <img
                                        src="/img/blog/note-taking-aval/note-taking-the-mapping-method.png"
                                        alt="Illustration of the mapping method"
                                        height="350px"
                                        style="float:left;margin-top: 40px;"
                                />
                                <h3 class="title">THE MAPPING METHOD</h3>
                            <p>I call it mind-mapping and this is the method I use when I need to revise a large topic.</p>
                            <p>You need to write the main topic in the center of the page and branch off this topic with as many subtopics and information as you feel you need to cover the whole topic.</p>
                            <p>This is a helpful method for those of you who need to visualize the information instead of reading it. You can color code it as well to make the whole process easier.</p>

                            </div>
                            <div><img
                                    src="/img/blog/note-taking-aval/note-taking-outlining-method.png"
                                    alt="Illustration of THE CORNELL METHOD"
                                    height="250px"
                                    style="float: right;margin-top: 30px"
                            /><h3 class="title">THE OUTLINING METHOD</h3>

                            <p>This one is kind of a long bullet point list that helps you organize the information by subordinating ideas to a subtopic, a more prominent topic, etc.
                            </p>
                            <p>This is a neat and clear method to take notes because you don’t need to worry about how the information will fit in the page as, for example, in the mapping method.</p>

                            </div>
                            <div style="margin-top: 30px;">
                                <img
                                        src="/img/blog/note-taking-aval/the-charting-method.png"
                                        alt="Illustration of the mapping method"
                                        height="300px"
                                        style="float:left;margin-top: 28px;"
                                />

                                <h3 class="title">THE CHARTING METHOD</h3>

                                <p>This method uses labeled columns for your notes. It allows you to add information about as many subjects as the number of columns you decide to draw without having to leave blank spaces in case something comes up. It is excellent for visualizing your notes and for seeing the relationships between the topics discussed.
                                </p>
                            </div>

                            <div>
                                <h3 class="title">THE SENTENCE METHOD</h3>
                                <img
                                        src="/img/blog/note-taking-aval/note-taking-the-sentence-method.png"
                                        alt="Illustration of THE CORNELL METHOD"
                                        height="250px"
                                        style="float: right;margin-top: -30px"
                                />
                                <p>I often combine this method with another from the ones above. This one consists of making a summary of an entire topic or subtopic in a single sentence. In this way you write down only important information and the notes are easier to go through before the exam.</p>
                                <p>Beside these note taking techniques, I have some advice I would have liked others to share with me before starting my exam life.
                                </p>
                            </div>
                            <h3 class="title">KEEP THEM SIMPLE</h3>
                            <p>This is the first rule I would give anyone about taking notes. You don’t need to make notes just for the sake of it. You have to think about how it is easier for you to learn, not how pretty they are. I am definitely pro pretty notes, but I am a rational one: keep it simple and as minimal as you can. Don’t use all the colours in your pencil case, don’t make calligraphy out of them. Keep in mind you have no time to lose so take your notes wisely.</p>

                            <h3 class="title">USE COLOR CODES</h3>
                            <p>It’s not that it makes your notes boring to be in a one-colour theme, but it’s difficult to find what you need in a sea of black ink. Use different colours for different things to find them easier. For example, you can use yellow highlighters for years and dates, pink for names and personalities, blue for equations and formulas and so on. Don’t underestimate the power of coordinated colours: it can save your life later when you look for specific information in a 200 pages notebook.</p>

                            <h3 class="title">USE POST-ITS</h3>
                            <p>There will always be temporary information you don’t want to make it permanent in your notebook: use a post-it. It will give colour to your page and it will help you remember to look that word in the dictionary or to redraw that diagram when you get home. It’s super easy to use and you can take it off whenever you want — that’s the biggest plus of using them!
                            </p>

                            <h3 class="title">USE FLASHCARDS</h3>
                            <p>This is the most important advice I can give you. Before an exam it is a lot more efficient to train your mind with a set of flashcards instead of rereading all your notes — studies have shown that. To be ahead of your exam revision keep a set of blank flashcards in your backpack and every time your professor talks about something that is possible to be in the exam — a question, a detail, almost anything — write it down on one side of the flashcard. You can then look it at home and write the explanation on the other side of the flashcard. In this way you can keep track of all the information you need to look for and you will also have a full pack of useful notes to train your mind before the exam.</p>
                            <p>P.S.: You can always use flashcards applications instead of paper flashcards — I am not just pro pretty notes, but environmentally friendly as well (or at least I try to be).</p>

                            <h3 class="title">BINDERS/SEPARATE NOTEBOOKS</h3>
                            <p>Don’t write all your notes on a single notebook — organise your course notes in different notebooks or binders. This will also help divide your mind in different drawers and keep different information in different “folders” in your mind. It will be much easier for you to revise and to remember information and exactly where to get it from.</p>

                            <h3 class="title">NOTEPAD FOR QUESTIONS</h3>
                            <p>Don’t mix lecture notes with questions you might have and want to find the answer to. If you don’t like the flashcards method, you can have a notepad on which to write down any questions you might have regarding your lectures. In this way you don’t interrupt your notes and you can remain organised.
                            </p>

                            <h3 class="title">COLOUR PENS/HIGHLIGHTERS</h3>
                            <p>You should choose a specific colour for each topic. In this way when you think about a topic it will pop out in your head a certain colour and it will be a lot easier to visualise the pages and find the information you look for in your head. Trust me on that.
                            </p>

                            <h3 class="title">GRAPHICS & DIAGRAMS</h3>
                            <p>There is no better way of understanding a concept than making a diagram or a graphic about it. This will help you visualise the whole concept faster and it will save you time from writing several sentences. Don’t forget to label it, to add details and colour to it.</p>

                            <h3 class="title">USE ABBREVIATIONS AND SYMBOLS</h3>
                            <p>Don’t spend your time writing again and again the same never-ending word. It’s your notebook, you are the one that must reread it over and over again so abbreviate as much as you can.</p>
                            <p>Simplify your work. Do not waste time writing and rewriting things. Focus on getting information written down and make your own system for abbreviations and symbols. “K” doesn’t have to mean “Potassium”, “OK” or “thousand”, it can mean anything you want it to mean. Don’t be afraid to personalize your system, you are the one that uses it.</p>

                            <h3 class="title">GLOSSARY FOR ABBREVIATIONS</h3>
                            <p>Since you have to keep track of your abbreviations, I suggest you keep a glossary at the end of the notebook where you should write all the abbreviations. It will simplify your life so much, believe me.</p>

                            <h3 class="title">TAKE YOUR LECTURER’S NOTES WITH YOU ON A TABLET/LAPTOP IN CLASS AND ADD INFORMATION TO THEM, DO NOT REWRITE THEM</h3>
                            <p>Read all your assigned material and your class notes before class, not after. You can read the materials you have available for the next week every week: in this way you will always be a step ahead of everyone. So, do the reading for week 1 before starting university, the reading for week 2 in week 1 and so on.</p>
                            <p>Instead of rewriting every word of your professor, take the online notes (PowerPoint slides or any other material you know will be used) with you in the class — use your tablet, laptop or any other device to follow the lecture and add on the sides or in your notebook only the necessary information that is not already written in the available notes. Don’t write everything you hear — you will end up having the same information twice.
                            </p>

                            <h3 class="title">BLANK SPACE BETWEEN NOTES</h3>
                            <p>Our minds rely on symmetry and framing. Don’t suffocate yourself: crowded notes don’t help you study more, it’s just an illusion. Give your notes some space, let some blank rows to add details later, write on two columns if you want, leave some blank space on the margins and always start a new chapter on a new page. It will help you be more relaxed when rereading your notes.</p>
                            <p>I shared with you all my tips & tricks on the note taking subject and I hope I helped your future student self to take notes effectively.
                            </p>
                            <p>Taking effective notes has a great impact on your retention and comprehension of the material, so don’t underestimate it. You may feel like you don’t know what to write during a lecture, but if you focus and pay attention to the lecture you will eventually learn to distinguish vital information from information you can find everywhere. As most of the important things in life, it takes time. Develop your own note-taking method, be organised, pay attention, review your notes and we will see each other waving first degrees after university.</p>


                            <card type="profile" plain>
                                <template slot="raw-content">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="card-avatar">
                                                <a href="#">
                                                    <img class="img img-raised" src="/img/marie.jpg">
                                                </a>
                                                <div class="ripple-container"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <h4 class="card-title">Adeline Valoschi</h4>
                                            <p class="description">To find out more about Valoschi and her beautiful study notes, visit <a target="_blank" href="#https://www.instagram.com/avalstudies/">@avalstudies</a> on Instagram.</p>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-default pull-right btn-round">Follow
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-blog-info">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="blog-tags">
                                        Tags:
                                        <span class="label label-primary">Note Taking </span>
                                        <span class="label label-primary">Studying </span>
                                        <span class="label label-primary">Flashcards </span>
                                    </div>
                                </div>
                                <!--<div class="col-md-6">
                                    <a href="#todo" class="btn btn-google btn-round pull-right">
                                        <i class="fab fa-google"></i> X
                                    </a>
                                    <a href="#todo" class="btn btn-twitter btn-round pull-right">
                                        <i class="fab fa-twitter"></i> X
                                    </a>
                                    <a href="#todo" class="btn btn-facebook btn-round pull-right">
                                        <i class="fab fa-facebook-square"></i> X
                                    </a>
                                </div>-->
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
  import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

  export default {
    name: 'blog-post',
    bodyClass: 'blog-post',
    components: {
      Card,
      InfoSection,
      Badge,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
  }
</script>

