<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('/img/bg24.jpg')"></parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Concentration</h2>
                        <h4>The importance of free time for concentration</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <!--<div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <a href="#" class="btn btn-primary btn-round btn-lg">
                                <i class="now-ui-icons text_align-left"></i> Read Article
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-twitter btn-round">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-facebook btn-round">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                            <a href="#" class="btn btn-icon btn-lg btn-google btn-round">
                                <i class="fab fa-google"></i>
                            </a>
                        </div>
                    </div>
                </div>-->
            </div>
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto" style="margin-top: -50px;">
                            <h3>The importance of free time for concentration</h3>
                            <p>Continuing on the topic of concentration, we can now explore how the way you manage your time in and out of work sessions influences your concentration quality and span. By investing little effort, you can create practices and habits that, like an autopilot can help you better this vital skill. As well, I would like to highlight that you could be having a more fulfilling free time and all while improving your so valuable concentration, amen to all this repetition. I hope after all the gibberish I didn’t induce semantic satiation for concentration.
                            </p>
                            <p>
                                The fact that we consider planning a work task leads to being more unstructured when it comes to our free time. As we avoid planning, we avoid thinking about it and when possible we go about it as spontaneously as possible; this is one interpretation of “freedom”. Do not get me wrong here; I am not going to propose to you to become a machine that logs every minute of their life. However, I will propose to you to consider giving it a little more thought.
                            </p>

                            <p>
                                Wouldn’t it be nice to make sure that the things that matter to us get their due space in our life? Wouldn’t it also be nice to excerpt real freedom and have a saying in how and what we do during our free time? What about that motorbike driving class you always wanted to take or the missing hours with your best friend or significant other? Time is a constrain and you will be able to profit from it greatly if you create a time budget and understand that your time is precious.
                            </p>

                            <p>
                                I have prepared a rough time estimate for you, every week; you have 168 hours, that’s it. Please don’t throw a tantrum and curl into the corner in panic. It’s all good; we can make the most out of it. You just need to understand it and use it in your favor. Remember, understanding is power!
                                Consequently, we use some 45h to study/work, then some 46-54h to sleep, 30-40h to eat, 3-5h sport, depending on if you are in the lower or the upper limits you will be left with either some 44h or with 34h left. Most probably something in between. This means you have a reasonable amount of time to commute (I forgot to calculate it) and enjoy leisure time.
                            </p>

                            <p>
                                If you have no idea, what would be great for your free time you will be left with a bad mouth taste afterward, and I promise you, you don’t want this. It isn’t that difficult. You might just learn to say no to the things that not really matter and say yes to those that have a greater value and impact for you.
                            </p>

                            <h3 class="title">The myth of free time</h3>
                            <p>
                                Have you heard the myth that free time should be spontaneous and lack structure to be enjoyable? Me too, and I am afraid I firmly have to disagree with it. Two issues come to mind immediately for me. On the one hand, as most people do, you will end up stress because “you have no time”. Since you have not structured and prioritized what is precisely what you want to do with your free time, the consequence, often times will be that the time will just go by and you did not even notice. Subsequently, you are left feeling as if you have no time. This is not the case because it is the first time that workers have as much free time as possible. I agree with you, I would love more free time, as a matter of fact.
                            </p>

                            <p>
                                On the other hand, the same lack of structure leads to you undertaken the most convenient tasks, unless you have made your choices. In which case, what are you doing reading this? You could end up Netflix binging, hooked on Instagram for an evening, shopping online or playing video games until the dawn comes. None of these activities in excess serves you.
                            </p>

                            <p>Nonetheless, enjoying social media, a movie, a tv show or playing for a while are all great and fun things to do, but mind your time. If you remain conscious of the span of time you want to invest in this activities, not only will you keep your time controlled, but also, you will increase your satisfaction and avoid derailing the efforts you have undertaken to keep your concentration in check. Consequently, having and finding time for all those amazing and exciting things you want to do, all while passible training your concentration. </p>

                            <p>Excessive time in screen content consumption induces continues release of dopamine into your brain, which makes you feel good in the moment. Nevertheless, whenever is missing you might experience withdraw and other uncomfortable emotions, leading to you going back and back again to the sources that release dopamine into your brain. Accordingly, this induces an intolerance to boredom without needing to reach out for a little dopamine fix on your screen. This hampers your concentration, which sometimes demands of you to bear with tasks that are less than amusing.</p>

                            <p>In short, the idea of blog post to support your concentration efforts and why not make you happier (which believe it or not helps you performer better) will be that you become aware of how you invest your free time. </p>

                            <p>Whenever you include screen time during these phases remain mindful of the time you put into them and the reward you get out of them, as well as, the negative byproduct they can generate, if you over do them. Case being a lost in your ability to concentrate for long periods.
                            </p>

                            <p>I suggest you begin by asking yourself the following questions:</p>
                            <ol>
                                <li>What would excite me?</li>
                                <li>What does make me feel more alive?</li>
                                <li>When I see which people do I feel the best?</li>
                                <li>Which activities/hobbies bring genuine joy to me?</li>
                            </ol>

                            <p>After you answer this, you will know some areas where you could prioritize and enjoy fully and truly your free time. Energizing yourself and remaining in control of your concentration, which is a keep asset for your learning process. </p>

                            <card type="profile" plain>
                                <template slot="raw-content">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="card-avatar">
                                                <a href="#">
                                                    <img class="img img-raised" src="/img/placeholder.jpg">
                                                </a>
                                                <div class="ripple-container"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <h4 class="card-title">A. Santacoloma</h4>
                                            <p class="description">.</p>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-default pull-right btn-round">Follow
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-blog-info">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="blog-tags">
                                        Tags:
                                        <span class="label label-primary">Note Taking </span>
                                        <span class="label label-primary">Studying </span>
                                        <span class="label label-primary">Flashcards </span>
                                    </div>
                                </div>
                                <!--<div class="col-md-6">
                                    <a href="#todo" class="btn btn-google btn-round pull-right">
                                        <i class="fab fa-google"></i> X
                                    </a>
                                    <a href="#todo" class="btn btn-twitter btn-round pull-right">
                                        <i class="fab fa-twitter"></i> X
                                    </a>
                                    <a href="#todo" class="btn btn-facebook btn-round pull-right">
                                        <i class="fab fa-facebook-square"></i> X
                                    </a>
                                </div>-->
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
  import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

  export default {
    name: 'blog-post',
    bodyClass: 'blog-post',
    components: {
      Card,
      InfoSection,
      Badge,
      Comment,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox
    },
  }
</script>
<style>
</style>
