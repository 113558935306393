<template>	
	<div class="wrapper">
        <div class="page-header page-header-normal">
            <parallax class="page-header-image"
                 :style="bgStyle">
            </parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h1 class="title">{{ test.title }} — Practice Test</h1>
                        <h4 class="title">{{ test.subtitle }}</h4>
                        <h4 class="description">{{ test.description }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container">
                <div class="card-diagram" v-if="$slots.diagram">
                    <slot name="diagram"></slot>
                </div>
                <div class="row">
  					<div class="col-md-10">
                		<n-button type="primary" @click.native="toggleHideAnswers" class="pull-right">Hide Answers</n-button>
                	</div>
                </div>
                
 <div class="row">
  <div class="col-md-10 ml-auto mr-auto">
  <!--<table class="el-table" v-if="isShowAnswers">
  	<thead>
  		<tr><th>#</th></tr>
  		<tr><th>Question</th></tr>
  		<tr><th>Answer</th></tr>
  	</thead>
    <tr v-for="test in test.items">
      <td min-width="200px">{{ test.front }}</td>
      <td v-show="isShowAnswers">{{ test.back }}</td>
    </tr>
  </table>-->
  
    <el-table :data="test.items">
      <!--<el-table-column type="index" class-name="d-none d-md-block"> Todo hide only mobile
      </el-table-column>-->
      <el-table-column min-width="150" prop="front" label="Question">
      <template slot-scope="scope">
          <span v-html="scope.row.front"></span>
        </template>
      </el-table-column>
      <el-table-column v-if="isShowAnswers" min-width="200" prop="back" label="Answer">
      	<template slot-scope="scope">
          <span v-html="scope.row.back"></span>
        </template>
      </el-table-column>
      <!--<el-table-column min-width="150" header-align="right" label="Actions">
        <div slot-scope="{row}" class="text-right table-actions">
          <el-tooltip content="Info" :open-delay="300" placement="top">
            <n-button type="info" size="sm" icon>
              <i class="now-ui-icons users_single-02"></i>
            </n-button>
          </el-tooltip>
          <el-tooltip content="Settings" :open-delay="300" placement="top">
            <n-button type="success" size="sm" icon>
              <i class="now-ui-icons ui-2_settings-90"></i>
            </n-button>
          </el-tooltip>
          <el-tooltip content="Delete" :open-delay="300" placement="top">
            <n-button type="danger" size="sm" icon>
              <i class="now-ui-icons ui-1_simple-remove"></i>
            </n-button>
          </el-tooltip>
        </div>
      </el-table-column>-->
    </el-table>
    
    <h4 v-if="test.recommendation" class="description"><em>Recommendation:</em> {{test.recommendation}}</h4>
  </div>
</div>           
        </div></div>
    
       <!-- FOR PRINTING TODO: <div class="main">
            <div class="section pt-0 pb-0">
                <div class="team-3 section-image" style="background-image: url('/img/bg26.jpg')">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 ml-auto mr-auto text-center">
                                <h2 class="title">{{ test.title }} — Practice Test</h2>
                                <h3 class="title">{{ test.subtitle }}</h3>
                                <h4 class="description">
                                	By {{ test.author }} 
                                </h4>
                            </div>
                         
                            
  
  
                            
                            
                            <div v-for="test in test.items" class="col-xl-6 col-lg-7 ml-auto mr-auto">
                                <card type="profile">
                                    <div slot="raw-content">
                                        
                                        <div class="col-xs-5">
                                            <div class="card-body">
                                                <p v-html="test.front"></p>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                                <card type="profile">
                                    <div slot="raw-content">
                                        
                                        <div class="col-xs-5">
                                            <div class="card-body">
                                               <em>Answer:</em> <p v-html="test.back"></p>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                            </div>
                        	<p class="text-white">References: <em>{{ test.references }}</em></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<style>
.cell ul {
  padding-left: 1.2em;
}
.cell h4 {
  font-size: 1.2em;
}
.cell h4 b {
  font-weight: 600;
  font-size: 0.9em;
}
</style>
<script>
import { Card, Button, FormGroupInput } from "@/components";
import { Table, TableColumn } from "element-ui";
import katex from "katex";

export default {
  name: "practice-tests",
  bodyClass: "blog-posts",
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    test: Object
  },
  methods: {
    toggleHideAnswers: function(event) {
      if (!this.isShowAnswers) {
        event.target.innerHTML = "Hide Answers";
        this.isShowAnswers = true;
      } else {
        event.target.innerHTML = "Show Answers";
        this.isShowAnswers = false;
      }
    }
  },
  mounted() {
    if (!document.getElementById("katexscript")) {
      // otherwise already loaded
      var headTag = document.getElementsByTagName("head")[0];
      var linkTag = document.createElement("link");
      linkTag.type = "text/css";
      linkTag.rel = "stylesheet";
      linkTag.id = "katexscript";
      linkTag.href =
        "https://cdn.jsdelivr.net/npm/katex@0.10.0/dist/katex.min.css";
      linkTag.integrity =
        "sha384-9eLZqc9ds8eNjO3TmqPeYcDj8n+Qfa4nuSiGYa6DjLNcv9BtN69ZIulL9+8CqC9Y";
      linkTag.setAttribute("crossorigin", "anonymous");
      headTag.appendChild(linkTag);
    }
    window.addEventListener("load", function(event) {
      var latexSpans = document.getElementsByClassName("latex");
      for (var i = 0; i < latexSpans.length; i++) {
        var latexSpan = latexSpans[i];
        katex.render(latexSpan.textContent, latexSpan);
      }
    });
  },
  data() {
    return {
      isShowAnswers: true,
      bgStyle: "background-image: url('" + this.test.bg + "')"
    };
  }
};
</script>
<style>
.el-table .cell {
  word-break: break-word;
  word-wrap: break-word;
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
}
</style>
