import Vue from "vue";
import Router from "vue-router";
import Components from "./pages/Components.vue";
import Presentation from "./pages/Presentation.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

import BlogPosts from "./pages/main/BlogPosts.vue";
import NoteTaking from "./pages/main/BlogPost.vue";
import BlogConcentration from "./pages/blog/ConcentrationAH.vue";
import BlogSpacedRep from "./pages/blog/SpacedRepetition.vue";

const ContactUs = () => import("./pages/main/ContactUs.vue");
import LandingPage from "./pages/main/LandingPage.vue";
import PricingPage from "./pages/main/PricingPage.vue";
import EcommercePage from "./pages/main/EcommercePage.vue";
import ProductPage from "./pages/main/ProductPage.vue";
const ProfilePage = () => import("./pages/main/ProfilePage.vue");
import Sections from "./pages/Sections.vue";
// Lazy loading routes
const PracticeTests = () => import('./pages/main/PracticeTests.vue');
const About = () => import('./pages/main/AboutUs.vue');
const QuestionGeneration = () => import('./pages/info/QuestionGeneration.vue');
const LoginPage  = () => import('./pages/main/LoginPage.vue');
const SignUpPage = () => import('./pages/main/SignUpPage.vue');
const Guide = () => import('./pages/main/Guide.vue');

// Privacy
const PrivacyPolicy = () => import('./pages/info/PrivacyPolicy.vue');
const TermsOfService = () => import('./pages/info/TermsOfService.vue');


// Practice Tests
const ScalingTeams = () => import('./pages/tests/001ScalingTeams.vue');
const DeepWork = () => import('./pages/tests/prod/002DeepWork.vue');
const BooleanAlgebra = () => import('./pages/tests/inf/101BooleanAlgebra.vue');
const DataLinkLayer = () => import('./pages/tests/inf/102DataLinkLayer.vue');
const NetworkLayer = () => import('./pages/tests/inf/103NetworkLayer.vue');
const TransportLayer = () => import('./pages/tests/inf/104TransportLayer.vue');
const NetworkSecurity = () => import('./pages/tests/inf/105NetworkSecurity.vue');
const DesignPatterns = () => import('./pages/tests/inf/110DesignPatterns.vue');




Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/components",
            name: "components",
            components: {default: Components, header: MainNavbar, footer: MainFooter},
            props: {
                footer: {backgroundColor: 'black'},
                header: {colorOnScroll: 65, transparent: false}
            },
            meta: {
                title: 'AutoLearnify - Practice Testing with Smart Items for Effective Learning'
            }
        },
        {
            path: "/",
            name: "presentation",
            components: {default: Presentation, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 150}},
            meta: {
                title: 'AutoLearnify - Artificial flashcard generation and study tools for effective learning',
                metaTags: [
                    {
                        name: 'description',
                        content: 'The AutoLearnify App leverages artificial flashcard generation and study tools for better learning: Flashcard generation, cloze tests and productivity tools.'
                    },
                    {
                        property: 'og:description',
                        content: 'The AutoLearnify App leverages artificial flashcard generation and study tools for better learning: Flashcard generation, cloze tests and productivity tools.'
                    }
                ]
            }
        },
        {
            path: "/uni",
            name: "blog",
            components: {default: BlogPosts, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}}
        },
        {
            path: "/uni/find-own-study-method-take-better-notes-at-university/",
            name: "blog",
            components: {default: NoteTaking, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}}
        },
        {
            path: "/uni/importance-of-free-time-for-concentration/",
            name: "blog",
            components: {default: BlogConcentration, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}}
        },
        {
            path: "/uni/exploit-spacing-effect-to-learn-more-effectively/",
            name: "blog",
            components: {default: BlogSpacedRep, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}}
        },
        {
            path: "/practicetests",
            name: "practicetests",
            components: {default: PracticeTests, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 50}}
        },
        {
            path: "/practicetests/deepwork-focus-in-distracted-world/",
            name: "deepwork",
            components: {default: DeepWork, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Deep Work - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice Tests on Deep Work: Rules for Focus in a Distracted World by Cal Newport' },
                    { property: 'og:description', content: 'Practice Tests on Deep Work: Rules for Focus in a Distracted World by Cal Newport'}
                ]
            }
        },
        {
            path: "/practicetests/scaling-teams-strategies-building-successful-teams/",
            name: "scalingteams",
            components: {default: ScalingTeams, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Scaling Teams - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice Tests to Scaling Teams: Strategies for Building Successful Teams and Organizations (Alexander Grosse and David Loftesness).' },
                    { property: 'og:description', content: 'Practice Tests to Scaling Teams: Strategies for Building Successful Teams and Organizations (Alexander Grosse and David Loftesness).'}
                ]
            }
        },
        {
            path: "/practicetests/data-link-layer",
            name: "datalinklayer",
            components: {default: DataLinkLayer, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Data Link Layer - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice test about the Data Link Layer' },
                    { property: 'og:description', content: 'Practice test about the Data Link Layer'}
                ]
            }
        },
        {
            path: "/practicetests/inf/network-layer-tcp-ip",
            name: "networklayer",
            components: {default: NetworkLayer, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Network Layer - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice test about the Network Layer (TCP/IP model)' },
                    { property: 'og:description', content: 'Practice test about the Network Layer (TCP/IP model)'}
                ]
            }
        },
        {
            path: "/practicetests/inf/transport-layer-tcp-ip",
            name: "transportlayer",
            components: {default: TransportLayer, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Transport Layer - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice test about the Transport Layer (TCP/IP model)' },
                    { property: 'og:description', content: 'Practice test about the Transport Layer (TCP/IP model)'}
                ]
            }
        },
        {
            path: "/practicetests/inf/network-security",
            name: "networksecurity",
            components: {default: NetworkSecurity, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Transport Layer - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice test about Network Security' },
                    { property: 'og:description', content: 'Practice test about Network Security' }
                ]
            }
        },
        {
            path: "/practicetests/boolean-algebra",
            name: "booleanalgebra",
            components: {default: BooleanAlgebra, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Boolean Algebra - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice test about Boolean Algebra' },
                    { property: 'og:description', content: 'Practice test about Boolean Algebra'}
                ]
            }
        },//DesignPatterns
        {
            path: "/practicetests/software-engineering/design-patterns",
            name: "designpatterns",
            components: {default: DesignPatterns, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'Design Patterns - Practice Test',
                metaTags: [{ name: 'description', content: 'Practice test about Design Patterns' },
                    { property: 'og:description', content: 'Practice test about Design Patterns'}
                ]
            }
        },
        {
            path: "/about",
            name: "about",
            components: {default: About, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 50}}
        },
        /*{
          path: "/contact",
          name: "contact",
          components: {default: ContactUs, header: MainNavbar, footer: MainFooter},
          props: {footer: {type: 'default'}}
        },*/
        {
            path: "/app",
            name: "login",
            components: {default: LoginPage, header: MainNavbar},
            props: {header: {colorOnScroll: 450}}
        },
        {
            path: "/login",
            redirect: "/app"
        },
        {
            path: "/question-generation",
            name: "questiongeneration",
            components: {default: QuestionGeneration, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'AutoLearnify - Question Generation',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Make use of question generation to generate Smart Items.'
                    },
                    {
                        property: 'og:description',
                        content: 'Make use of question generation to generate Smart Items.'
                    }
                ]
            }
        },/*
    {
      path: "/landing",
      name: "landing",
      components: {default: LandingPage, header: MainNavbar, footer: MainFooter},
      props: {footer: {type: 'default'}, header: {colorOnScroll: 450}}
    },
    {
      path: "/pricing",
      name: "pricing",
      components: {default: PricingPage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/blog-post",
      name: "blog-post",
      components: {default: BlogPost, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/blog-posts",
      name: "blog-posts",
      components: {default: BlogPosts, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/ecommerce",
      name: "ecommerce",
      components: {default: EcommercePage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/product",
      name: "product",
      components: {default: ProductPage, header: MainNavbar},
      props: {header: {colorOnScroll: 450}}
    },
    {
      path: "/profile",
      name: "profile",
      components: {default: ProfilePage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 450}}
    },*/
        {
            path: "/signup",
            name: "signup",
            components: {default: SignUpPage, header: MainNavbar},
            props: {header: {colorOnScroll: 0}}
        },
        {
            path: "/legal/privacy-policy",
            name: "privacypolicy",
            components: {default: PrivacyPolicy, header: MainNavbar, footer: MainFooter},
            props: {
                footer: {backgroundColor: 'gray'},
                header: {colorOnScroll: 0}
            }
        },
        {
            path: "/legal/terms-of-service",
            name: "termsservice",
            components: {default: TermsOfService, header: MainNavbar, footer: MainFooter},
            props: {
                footer: {backgroundColor: 'gray'},
                header: {colorOnScroll: 0}
            }
        },
        {
            path: "/guide/get-started",
            name: "Guide",
            components: {default: Guide, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 0}},
            meta: {
                title: 'AutoLearnify - Getting Started',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Want to learn how to use AutoLearnify? Here\'s how to get started with us.'
                    },
                    {
                        property: 'og:description',
                        content: 'Want to learn how to use AutoLearnify? Here\'s how to get started with us.'
                    }
                ]
            }
        },
    ],
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return { x: 0, y: 0 }
        }
    },
});

export default router;

/** Added */
router.beforeEach((to, from, next) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        .forEach(tag => document.head.appendChild(tag));

    next();
});
