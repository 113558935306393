<template>
  <div class="wrapper presentation-page">
    <div class="section section-basic-components">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-7 ml-auto mr-auto">
            <h2 class="title" style="margin-bottom:5px">Learn with <br>Smart Items</h2><!--more in less time-->
            <h6 class="category" style="color:#0055FE;margin-top:5px; margin-bottom: 20px;">
              More effective studying with Smart Flashcards
            </h6>
            <h3 class="description">
              AutoLearnify helps you learn more effectively with Smart
              Flashcards on any device.
            </h3>
              <!--"#https://app.autolearnify.com/home?isTryAccount=true#/dashboard" target="_blank"-->
              <a href="/guide/get-started/"
              rel="nofollow"
              class="btn btn-primary btn-lg mr-3"
            >
              Start App
            </a>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="image-container">
              <img
                class="components-macbook"
                src="/img/home/landing-learn-robo.svg"
                alt="App on iPad"
              />
              <!--<picture class="table-img">
                                <source srcset="/img/home/iPad-Iphone-Quetion-Generation.webp" type="image/webp">
                                <source srcset="/img/home/iPad-Iphone-Quetion-Generation.png" type="image/png">
                                <img src="/img/home/iPad-Iphone-Quetion-Generation.png" alt="Question Generation Input">
                            </picture>-->
              <img
                class="table-img"
                src="/img/home/autolearnify_ipad_silver_landscape-800.png"
                alt="Question Generation Input"
              />
              <!--<img class="share-btn-img" src="/img/presentation-page/share-btn.jpg" alt="share button">
                            <img class="coloured-card-btn-img" src="/img/presentation-page/coloured-card-with-btn.jpg"
                                 alt="coloured card with button">
                            <img class="coloured-card-img" src="/img/presentation-page/coloured-card.jpg"
                                 alt="coloured card">-->
              <!--<img class="social-img" src="/img/presentation-page/social-row.jpg" alt="social row">-->
              <!--<img class="linkedin-btn-img" src="/img/presentation-page/linkedin-btn.jpg"
                                 alt="linked in button">-->
            </div>
          </div>
        </div>
      </div>
      <div class="top-50-desktop">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="info info-hover">
                <div class="icon icon-sec icon-circle">
                  <i class="now-ui-icons design-2_ruler-pencil"></i>
                </div>
                <h4 class="info-title">Productivity System</h4>
                <p class="description">
                    Improve focus and finish work faster with the habits of stress-free productivity.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="info info-hover">
                <!--<div class="">
                  <img
                    style="max-height: 180px;margin-top:-30px;"
                    src="svg/logo/logo-icon.svg"
                    alt="Study Keep"
                  />
                </div>-->

                                    <div class="icon icon-info icon-circle">
                                        <i class="now-ui-icons education_atom"></i>
                                    </div>
                                    <h4 class="info-title">Flashcard Generation</h4>
                <p class="description">
                    <!--With machine-aided
                    <a href="/question-generation" class="link footer-link"
                      >Question Formation</a
                    >,-->AutoLearnify supports you in learning effectively through machine-aided flashcard generation.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="info info-hover">
                <div class="icon icon-sec icon-circle">
                  <i class="now-ui-icons location_compass-05"></i>
                </div>
                <h4 class="info-title">Spaced Repetition</h4>
                <p class="description">
                  Exploit the psychological spacing effect <!--through the use of Spaced Repetition -->
                  to remember things indefinitely in memory. <!--This learning technique enables you to
                  remember a large number of items indefinitely in memory.-->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--</div>
       	<header-main /
        <div class="section">>-->
      <!--<div class="separator-line separator-primary"></div>-->
      <!--<div class="about-description text-center">
                <div class="features-3">-->

      <!-- </div>
            </div>-->
      <div class="separator-line separator-primary" />
      <div class="projects-5">
        <div class="container">
          <div class="">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">
                Accelerate your learning. Learn anytime, anywhere.
              </h2>
              <h4 class="description">
                <span class="autolearnify">Auto Learnify</span> automatically
                generates your smart items through question generation and
                cloze deletion test formation. <!--Artificial intelligence helps you
                to learn in the most effective way.-->
              </h4>
            </div>
          </div>
          <div class="row">
            <!--     *********     FEATURES 5      *********      -->
            <div class="features-5" style="width: 100%;margin-top:20px;"> <!-- width: 100% is important otherwise sometimes strange problem in Chrome -->

                <div class="row">
                  <div class="col-md-6 ml-auto">
                    <!--<h2 class="title">Working is a pleasure</h2>-->
                    <div class="info info-horizontal">
                      <div class="icon icon-primary icon-circle">
                        <i class="now-ui-icons education_atom"></i>
                      </div>
                      <div class="description">
                        <h4 class="info-title">Test Generation</h4>
                        <p>
                          AutoLearnify automatically generates your flashcards
                          based on the text you have to learn.
                        </p>
                      </div>
                    </div>
                    <div class="info info-horizontal">
                      <div class="icon icon-default icon-circle">
                        <i class="now-ui-icons business_chart-bar-32"></i>
                      </div>
                      <div class="description">
                        <h4 class="info-title">Track your Progress</h4>
                        <p>
                          The statistics category shows all your stats at a
                          glance to help you stay on track.
                        </p>
                      </div>
                    </div>
                    <div class="info info-horizontal">
                      <div class="icon icon-primary icon-circle">
                        <i class="now-ui-icons objects_spaceship"></i>
                      </div>
                      <div class="description">
                        <h4 class="info-title">Get Things Done</h4>
                        <p>
                          Get even more productive thanks to a set of tools
                          designed to accomplish goals faster and save more time.
                        </p>
                        <!-- TODO check spelling become productive -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 ml-auto">
                    <div class="tablet-container">
                      <img
                        v-lazy="'/img/home/question-generation-nlp.svg'"
                        style="max-width: 400px;" />
                    </div>
                  </div>

                    <div class="col-md-6" style="margin-top: 53px; text-align: right;">
                        <card
                                type="background"
                                raised
                                style="background-image: url('/img/home/1689-learning-brainstorming-500.png'); background-position: 0 -40px; max-width: 375px;"
                        >
                            <div style="background-color: rgb(255 255 255 / 55%); border-radius: 5px;">
                                <h2 class="card-title" style="margin-top:285px;color:#111111; padding-top: 8px;">
                                    Effective Learning
                                </h2>
                                <p class="card-description">
                                    Create, connect, and convert knowledge.
                                    <br>
                                    <span style="display:block;margin-top:10px;font-style: italic">“Tell me and I forget, teach me and I may remember, involve me and I learn.”
                        -Benjamin Franklin.</span>

                                </p>
                                <badge type="neutral">Learning</badge>
                            </div>
                        </card>
                    </div>
                    <div class="col-md-6" style="margin-top: 30px;">
                        <info-section
                                type="primary"
                                icon="now-ui-icons education_paper"
                                title="Cloze Tests"
                                description="AutoLearnify automatically generates cloze tests from any English text. It finds the most beneficial gaps for you automatically."
                        />

                        <info-section
                                type="warn"
                                icon="now-ui-icons education_glasses"
                                title="Scientific Formulas"
                                description="The AutoLearnify app supports LaTeX to make learning scientific subjects a visual pleasure."
                        />

                        <info-section
                                type="primary"
                                icon="now-ui-icons location_world"
                                title="Work with Anyone"
                                description="Soon: Whether it is with your friend or classmate, AutoLearnify lets you learn with anyone."
                        />
                        <info-section
                                type="warn"
                                icon="now-ui-icons arrows-1_refresh-69"
                                title="Always in Sync"
                                description="No matter where you are, AutoLearnify stays in sync across all of your devices."
                        />
                    </div>

                </div>
            </div>
            <!--     *********    END FEATURES 5      *********      -->
          </div>
          <hr />
          <div class="row">
            <div class="col-md-5 ml-auto mt-5">
              <info-section style="margin-top:-60px;"
                type="warning"
                icon="now-ui-icons objects_diamond"
                title="Smart Items"
                description="Flashcards are suitable for refreshing and testing knowledge. Done right, they contain the most necessary facts and summarize them well. Smart Items are even better. The system automatically recognizes which topics and questions you know and where you need more practice. Furthermore, you don’t even have to create them yourself because AutoLearnify does this automatically with the help of an AI."
              />
                <a
                        href="/guide/get-started/"
                        class="btn btn-simple btn-primary btn-round mt-4"
                        style="margin-left: 35px;"
                >Learn more</a
                >
            </div>
            <div class="col-md-5 mr-auto" style="text-align: right">
              <card
                type="background"
                raised
                class="card-background-product"
                style="background-image: url('/img/home/brain-with-digital-circuit-learning-1000.jpg');max-width:400px;"
              >
                <h2 class="card-title" style="margin-top:-40px; color:black">Smart Items</h2>
                <p class="card-description">
                  <!--<span class="autolearnify" style="word-spacing:-0.2em"
                    >Auto Learnify</span
                  >’s Smart Items enable you to study in a fun, flexible
                  and rewarding way.-->
                </p>
                <badge type="neutral">Flashcards</badge>
              </card>
            </div>
          </div>
        </div>
      </div>

      <div class="about-contact">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mr-auto ml-auto">
              <h2 class="text-center title">Want to get in touch with us?</h2>
              <h4 class="text-center description">
                Contact us about any further collaboration. We will get back to
                you in a couple of hours.
              </h4>
              <form class="contact-form">
                <div class="row">
                  <div class="col-md-4">
                    <label>Your name</label>
                    <fg-input
                      addon-left-icon="now-ui-icons users_circle-08"
                      v-model="form.firstName"
                      placeholder="First Name..."
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <label>Your email</label>
                    <fg-input
                      addon-left-icon="now-ui-icons ui-1_email-85"
                      v-model="form.email"
                      placeholder="Email Here..."
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-4">
                    <label>Type</label>
                    <fg-input>
                      <el-select
                        class="select-default"
                        v-model="form.speciality"
                      >
                        <el-option
                          class="select-default"
                          value="1"
                          label="I'm a Student"
                        ></el-option>
                        <el-option
                          class="select-default"
                          value="2"
                          label="I'm a Teacher"
                        ></el-option>
                        <el-option
                          class="select-default"
                          value="3"
                          label="Other"
                        ></el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto text-center">
                    <a
                      href="mailto:info@autolearnify.com"
                      class="btn btn-primary btn-round mt-4 btn-lg mailto"
                      >Let's talk</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  InfoSection,
  FormGroupInput,
  Checkbox,
  MainHeader,
  MainFooter
} from "@/components";
import { Select, Option } from "element-ui";

export default {
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    MainHeader,
    MainFooter,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        speciality: ""
      }
    };
  },
  methods: {}
};
</script>
<style>
    .col-md-5 .info {
        margin-left: -15px;
    }
    .projects-5 .info p {
        text-align: justify;
        hyphens: auto;
    }
    div.projects-5 {
        margin-top: 30px;
    }
    @media only screen
    and (max-device-width: 600px) {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
        img {
            max-width: 100% !important;
        }
        .no-mob {
            display: none;
        }

        .row .card {
            margin-top: 30px; /* For example Smart Items*/
        }
    }
    @media only screen
    and (min-device-width: 601px) {
        .top-50-desktop {margin-top: 50px;}
    }
</style>
